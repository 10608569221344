/*@ngInject*/
function PipelinesController($log, $mdDialog) {
  const a = "\u03B1";
  const chi = "\u03C7";

  this.availablePipes = [
    {
      key: "hwe",
      name: "Hardy Weinberg",
      description: `Excludes sites out of HWE test (${chi}2 approx.)`,
      statName: a,
      nominalThreshold: 0.05,
      defaults: {
        critValue: 0.05,
        numSamples: 0
      }
    },
    {
      key: "binomMaf",
      name: "Allele Frequency",
      description: `
      Drop sites different thatn chose population allele frequency estimates
      <br/>
      at the bonferroni-corrected 2-sided alpha.
      <br/>
      Checks gnomad.genomes.af & gnomad.exomes.af by default
      `,
      statName: a,
      nominalThreshold: 0.025,
      defaults: {
        critValue: 0.025,
        estimates: ["gnomad.genomes.af", "gnomad.exomes.af"],
        privateMaf: 1e-4,
        // don't filter indels
        snpOnly: true,
        // will get overwritten during configuration
        numSamples: 0
      }
    }
    // {
    //   key: "dropSamples",
    //   name: "Drop Samples",
    //   description: `
    //   Drop samples found in the provided lists
    //   <br/>
    //   Adjust sample-related counts, assuming diploid
    //   <br/>
    //   (an, ac, sampleMaf, heterozygosity, etc)
    //   `,
    //   defaults: {
    //     samples: []
    //   }
    // }
  ];

  this.pipes = [];
  this.pipesInfo = {};
  this.bonferroni = 1;
  this.configs = {};
  this.numSamples = 0;

  const _clearPipe = () => {
    this.pipes = [];
    this.pipesInfo = {};
    this.bonferroni = 1;
    this.configs = {};
    this.numSamples = 0;
  };

  this.$onChanges = cObj => {
    if (cObj.job) {
      _clearPipe();

      if (cObj.job.currentValue) {
        const job = cObj.job.currentValue;

        this.configs =
          (job.search.pipelines && job.search.pipelines.config) || {};

        this.numSamples = this.job.getNumSamples();
      }
    }

    if (cObj.queryData) {
      if (cObj.queryData.currentValue) {
        this.bonferroni = cObj.queryData.currentValue.hits.total;
      }
    }
  };

  const _addPipe = (filterKey, defaultObj) => {
    let config;

    if (!this.configs[filterKey]) {
      const corrected = defaultObj.nominalThreshold / this.bonferroni;

      config = Object.assign({}, defaultObj.defaults, {
        critValue: corrected.toExponential(2)
      });
    } else {
      config = Object.assign({}, this.configs[filterKey]);
    }

    config.key = filterKey;
    config.numSamples = this.numSamples;

    this.pipes.push(config);

    this.pipesInfo[filterKey] = {
      config,
      order: this.pipes.length
    };

    this.onPipe({ pipeline: this.pipes });
  };

  const _removePipe = fKey => {
    if (this.pipesInfo[fKey] === undefined) {
      return;
    }

    const { order } = this.pipesInfo[fKey];

    this.pipes.splice(order - 1, 1);
    delete this.pipesInfo[fKey];

    Object.keys(this.pipesInfo).forEach(filterKey => {
      if (this.pipesInfo[filterKey].order > order) {
        this.pipesInfo[filterKey].order = this.pipesInfo[filterKey].order - 1;
      }
    });
  };

  this.togglePipe = (filterKey, defaultObj) => {
    if (this.pipesInfo[filterKey] !== undefined) {
      _removePipe(filterKey);
      return;
    }

    _addPipe(filterKey, defaultObj);
  };

  // this.showSampleConfigDialog = ev =>
  //   $mdDialog.show({
  //     controller: function DialogController($scope, chosenSamples = []) {
  //       // $log.debug('existingSynonyms are', existingSynonyms);

  //       if (chosenSamples.length) {
  //         const chosenSamples = [];

  //         const seen = {};
  //         chosenSamples.forEach(sample => {
  //           if(seen[sample]) {
  //             return;
  //           }

  //           seen[sample] = true;

  //           chosenSamples.push(sample)

  //         });
  //       } else {
  //         $scope.chosenSamples = [];
  //       }

  //       $scope.addSynonymToArray = function() {
  //         $scope.synonymsArray.push({
  //           name: "",
  //           value: ""
  //         });
  //       };

  //       $scope.removeSynonym = function() {
  //         $scope.synonymsArray.pop();
  //       };

  //       $scope.attachCasesAndControls = function() {
  //         const synonyms = {};

  //         $scope.synonymsArray.forEach(synonym => {
  //           // $log.debug('synonym', synonym);
  //           if (synonym.name.match(/^s+$/)) {
  //             return;
  //           }

  //           let synonymValue;
  //           if (typeof synonym.value === "string") {
  //             if (synonym.value.match(/^\s+$/)) {
  //               return;
  //             }

  //             synonymValue = synonym.value
  //               .split(/[\r\n]/)
  //               .map(val => val.trim());
  //           } else {
  //             synonymValue = synonym.value;
  //           }

  //           synonyms[synonym.name.trim()] = synonymValue;
  //         });

  //         // $log.debug('after transform, synonyms are', synonyms);
  //         $mdDialog.hide(synonyms);
  //       };

  //       $scope.cancel = function() {
  //         $mdDialog.cancel();
  //       };
  //     },
  //     templateUrl:
  //       "jobs/results/search/pipelines/jobs.results.search.pipelines.hwe.config.tpl.html",
  //     parent: angular.element($document.body),
  //     locals: {
  //       existingSynonyms: this.synonyms
  //     },
  //     targetEvent: ev,
  //     clickOutsideToClose: true
  //   });

  // this.setCaseControls = () => {
  //   this.showSynonymDialog().then(synonyms => {
  //     if (!Object.keys(synonyms).length) {
  //       return;
  //     }

  //     this.synonyms = Object.assign({}, synonyms);

  //     this.synonymsLowerCase = {};

  //     Object.keys(this.synonyms).forEach(name => {
  //       this.synonymsLowerCase[name.toLowerCase()] = this.synonyms[name];
  //     });

  //     $http
  //       .post(
  //         `${SETTINGS.apiEndpoint}jobs/${this.jobResource._id}/addSynonyms`,
  //         {
  //           synonyms: this.synonyms
  //         }
  //       )
  //       .then(() => this.searchInput(this.query));
  //   });
  // };
}

angular
  .module("sq.jobs.results.search.pipelines.component", [])
  .component("sqSearchPipelines", {
    bindings: {
      job: "<",
      queryData: "<",
      onPipe: "&"
    }, // isolate scope
    templateUrl:
      "jobs/results/search/pipelines/jobs.results.search.pipelines.tpl.html",
    controller: PipelinesController
  });
